

















































  import { Validator } from '@/constant/Mixins';
  import { ServerResponse } from '@/services/response.types';
  import Component from 'vue-class-component';
  import { Mixins } from 'vue-property-decorator';
  import { Action } from 'vuex-class';

  @Component({
    name: 'changePassword',
    components: {},
    mixins: [],
  })
  export default class changePassword extends Mixins(Validator) {
    @Action('ChangePassword', { namespace: 'auth' }) ChangePassword: (params: any) => Promise<ServerResponse>;

    public form = {
      password: '',
      new_password: '',
      confirm_password: '',
    };

    public visibility = {
      password: false,
      new_password: false,
      confirm_password: false,
    };

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }

    /**
     * OnSubmit
     */
    public OnSubmit(event: Event) {
      this.ChangePassword(new FormData(event.target as HTMLFormElement));
    }
  }
